import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const BlogPost = () => {
  return (
    <div class="py-16 bg-white">
      <div class="relative px-4 py-20 sm:px-6 lg:px max-w-6xl mx-auto -mt-32 bg-white">
        <div class="text-lg mx-auto">
          <h1>
            <span class="block text-base text-center text-green-600 font-semibold tracking-widest uppercase mb-5">
              Blog Post - Local Community
            </span>
            <span class="mt-2 mb-20 block text-5xl text-center font-serif leading-tight font-extrabold tracking-tight text-gray-900 sm:text-5xl">
              Littering at St Clere
            </span>
          </h1>
        </div>
        <div class="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
          <h4>
            As hard as the team at St Clere work to look after the landscape, we
            never seem to be able to keep up with those who are hell-bent on
            ruining it.
          </h4>
          <p>
            There’s the full on criminals. The ones who drive off the A20 and
            unload a skip’s worth of asbestos. Those who dump the corpses of
            horses (it’s happened twice) or, worse, a dying horse (also twice).
            There’s the garage who regularly throw thousands of tyres over
            woodland verges or over hedges, sometimes they then set fire to
            them.
          </p>

          <figure class="py-10">
            <StaticImage
              className="rounded-lg"
              src="../../images/tyres.jpg"
              placeholder="blurred"
              layout="fullWidth"
              aspectRatio={4 / 4}
              alt="Oaky"
            />
            <figcaption>
              Someone is making a concious decision to offload their operating
              costs to someone else's business.
            </figcaption>
          </figure>

          <p>
            Then there’s the middle ground. The builders who have done the house
            or garden clearance and can’t be arsed/don’t want to pay to take it
            to the tip. Carpeting, broken mirrors, furniture, old records.
            Sifting through the detritus is heart-breaking, often it seems like
            a whole life or home just dumped, belongings that once gave comfort
            and belonging, now just trashing the countryside.
          </p>
          <figure class="py-10">
            <StaticImage
              className="rounded-lg"
              src="../../images/rubbish_clearance.jpeg"
              placeholder="blurred"
              layout="fullWidth"
              aspectRatio={4 / 4}
              alt="Oaky"
            />
            <figcaption>
              It's probable that someone paid to have their rubbish disposed of,
              only to have it dumped on someone's land.
            </figcaption>
          </figure>
          <p>
            And the hardest of all to understand; the casual chucker. Presumably
            those who casually chuck a can or a car-litter bag, or a dirty nappy
            or a McDonalds bag out of their car door think it means nothing. It
            means everything. At least half of the bulk of any clearance we do
            at St Clere is made up of this kind of waste. There’s the guy
            (probably) with the drink problem (presumably) who chucks his
            Tenants Extra can up Terry’s Lodge Road. Over a year, he gets
            through thousands. They are jagged and rust and are ugly and
            dangerous to the wildlife that tries to live on the verges,
            hedgerows and woodland edges. There’s a long-life-banana-milk
            drinker who does the same thing along the Kemsing Road leaving
            hundreds of screwed up plastic bottles. People who have picnics at
            scenic spots and leave all their empties.
          </p>

          <figure class="py-10">
            <StaticImage
              className="rounded-lg"
              src="../../images/hedge_rubbish.jpg"
              placeholder="blurred"
              layout="fullWidth"
              aspectRatio={4 / 5}
              alt="Oaky"
            />
            <figcaption>
              Imagine being such a habitual litterer that you have a designated
              "chucking" point on your drive home.
            </figcaption>
          </figure>

          <p>
            It is beyond me. What is wrong with humanity that anyone does this?
            It’s impossible to do a litter picking session without asking deep
            existential questions about what we are getting wrong.
          </p>
          <p>
            And yet. St Clere facebook posts about litter picking are the most
            popular we ever post. People really really care. It infuriates our
            community, it puzzles us all, it demands action. I started CleanUp
            Sevenoaks as an area wide anti-litter campaign and people from all
            over the place signed up to help out within hours of the launch. Our
            plan is to encourage people to litter-pick regularly and to educate
            those in one of the groups above. But mostly the aim is to engage
            the children of our area. In my opinion it’s children who have
            driven forward acceptance of the climate change message. They can do
            the same with littering and fly tipping, I hope.
          </p>
        </div>

        <div class="max-w-6xl mx-auto px-4 py-12 sm:px-6 lg:py-16 lg:px-8">
          <div class="px-6 py-6 bg-gray-200 rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center text-center">
            <div>
              <div class="">
                <h2 class="text-4xl font-serif font-bold mb-5">
                  Having waste removed?
                </h2>
                <h3 class="text-2xl font-bold mb-10">Don't end up in court.</h3>
              </div>
              <p class="mb-10">
                By law, it is the householder who is responsible for making sure
                their waste is disposed of safely and legally. When fly tipping
                occurs, it is often carried out by illegal waste carriers who
                promise to take your waste away for a bargain fee but then dump
                it. <strong>If the waste is dumped illegally</strong>, the waste
                could be traced back to you rather the person who dumped it and{" "}
                <strong>you would be held responsible.</strong> If you are
                looking to start on a household project, consider what waste you
                may generate and how you will dispose of it once the job is
                done.
              </p>

              <p class="font-bold">
                Before hiring any individual or company take down the individual
                or company details and check with the Environment Agency that
                they are a registered waste carrier.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogPost
