import React from "react"
import SEO from "../../components/seo"
import BlogPost from "../../components/BlogPost"
import Footer from "../../components/Footer"
import BlogHeader from "../../components/BlogHeader"

const StClereBlogPost = () => {
  return (
    <>
      <BlogHeader imageSrc="/images/stclere.jpg" halfHeight />
      <SEO title="Rubbish at St Clere" />
      <BlogPost />
      <Footer />
    </>
  )
}

export default StClereBlogPost
